// ModalContext.js
import React, { createContext, useContext, useState } from "react"

const ModalContext = createContext()

export const useModalContext = () => {
  const context = useContext(ModalContext)
  if (context === undefined) {
    // Instead of throwing, return a default context
    return { isOpen: false, toggle: () => {} }
  }
  return context
}

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [placeholder, setPlaceholder] = useState("Your message")

  const toggle = (newPlaceholder = "Your message") => {
    setIsOpen(!isOpen)
    setPlaceholder(newPlaceholder)
  }

  return (
    <ModalContext.Provider value={{ isOpen, toggle, placeholder, setIsOpen }}>
      {children}
    </ModalContext.Provider>
  )
}
